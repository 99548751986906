import React, { useMemo } from "react";
import styled from "styled-components";
import { Container, Hidden } from "@material-ui/core";
import { LogoLink } from "../LogoLink";
import { routes } from "../../../../../../../lib/routes";
import { MobileMenu } from "../MobileMenu";
import { Link } from "gatsby";
import { NavLink } from "./components/NavLink";
import { useTranslation } from "react-i18next";
import { muiTheme } from "../../../../../../../lib/Theme";
import { rgba } from "polished";

export interface HeaderBottomSectionProps {
  className?: string;
  darkHeader?: boolean;
}

const HeaderBottomSectionInner = (props: HeaderBottomSectionProps) => {
  const { t } = useTranslation("common");
  return (
    <div className={props.className}>
      <Container component="nav" className="container">
        <LogoLink dark={props.darkHeader} />
        <Hidden smDown>
          <div className="nav-items">
            {Object.values(routes)
              .filter(route => route.displayInMenu !== false)
              .map(route => (
                <NavLink
                  key={route.to}
                  to={route.to}
                  label={t(route.translationKey)}
                />
              ))}
            <NavLink
              className={"cta-link"}
              to={routes.poschodoch.to}
              label={t(routes.poschodoch.translationKey)}
            />
          </div>
        </Hidden>
        <Hidden mdUp>
          <MobileMenu iconColor={props.darkHeader && "white"} />
        </Hidden>
      </Container>
    </div>
  );
};

export const HeaderBottomSection = styled(HeaderBottomSectionInner)`
  display: flex;
  height: 90px;
  align-items: center;

  background: ${props => (props.darkHeader ? rgba("#000", 0.5) : "white")};

  ${props => props.darkHeader && `border-bottom: 2px solid white;`}

  ${NavLink} {
    ${props => props.darkHeader && `color: white;`}
    &:hover {
      ${props => props.darkHeader && `color: ${props.theme.color.primary};`}
    }
  }

  ${LogoLink} {
    align-items: center;
  }

  .cta-link {
    &:hover {
      .label-wrapper {
        background: transparent;
        color: ${props => props.theme.color.primary};
        padding: 10px 15px;
      }
    }
    &.active {
      color: ${props => props.theme.color.primary};
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        background: ${props => props.theme.color.primary};
      }
    }
    .label-wrapper {
      background: ${props => props.theme.color.primary};
      color: white;
      padding: 10px 15px;
      border: 3px solid ${props => props.theme.color.primary};
      transition: all 200ms ease-in-out;
    }
  }

  .container {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    position: relative;

    ${muiTheme.breakpoints.down("sm")} {
      justify-content: center;
    }
  }

  ${MobileMenu} {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
  }

  .nav-items {
    display: flex;
    align-items: center;
  }
`;
