import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { Container, Box } from "@material-ui/core";
import SvgContactIcon from "../../../../../../../assets/icons/ContactIcon";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { TopHeaderItem, TopHeaderItemProps } from "./components/TopHeaderItem";
import { TiContacts } from "react-icons/ti";
import { BiMessageDetail, BiPhoneCall } from "react-icons/bi";

export interface HeaderTopSectionProps {
  className?: string;
}

const HeaderTopSectionInner = (props: HeaderTopSectionProps) => {
  const { t } = useTranslation("common");
  const topHeaderItems: TopHeaderItemProps[] = useMemo(
    () => [
      {
        icon: <TiContacts />,
        text: "",
        component: (
          <a
            href="/documents/ALTRO_D3_Zmena správcovskej spoločnosti.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("header.topSection.changeOfServicingCompany")}
          </a>
        )
      },
      {
        icon: <BiMessageDetail />,
        text: "",
        component: (
          <a href={`mailto:${t("contactInformation.email")}`}>
            {t("contactInformation.email")}
          </a>
        )
      },
      {
        icon: <BiPhoneCall />,
        text: `${t("header.topSection.callUs")}: ${t(
          "contactInformation.phoneNumber"
        )}`
      }
    ],
    []
  );
  return (
    <div className={props.className}>
      <Container>
        <div className="items">
          {topHeaderItems.map((topHeaderItem, index) => (
            <Box key={index} mr={3}>
              <TopHeaderItem {...topHeaderItem} />
            </Box>
          ))}
        </div>
      </Container>
    </div>
  );
};

export const HeaderTopSection = styled(HeaderTopSectionInner)`
  height: 45px;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${props => props.theme.color.primary};

  .items {
    display: flex;
    justify-content: flex-end;
  }
  a {
    color: white;
  }
`;
