import React from "react";
import styled from "styled-components";
import { Container, Grid, Hidden } from "@material-ui/core";
import { HeaderUnderline } from "../../../../../HeaderUnderline/HeaderUnderline";
import { useTranslation } from "react-i18next";
import SvgLogoDark from "../../../../../../../assets/LogoDark";

import { BiRightArrowAlt } from "react-icons/bi";
import { ImportantLink } from "./components/ImportantLink";
import { routes } from "../../../../../../../lib/routes";
import SvgAltroLogo from "../../../../../../../assets/AltroLogo";
import { TiContacts } from "react-icons/ti";
import { TopHeaderItem } from "../../../Header/components/HeaderTopSection/components/TopHeaderItem";
import { shade } from "polished";

export interface FooterTopSectionProps {
  className?: string;
}

const importantLinks = [
  {
    tk: "footer.importantLinks.links.poschodoch",
    to: routes.poschodoch.to
  },
  {
    tk: "footer.importantLinks.links.services",
    to: routes.services.to
  },
  {
    tk: "footer.importantLinks.links.documents",
    to: routes.documents.to
  },
  // {
  //   tk: "footer.importantLinks.links.announcements",
  //   to: routes.announcements.to
  // },
  {
    tk: "footer.importantLinks.links.gdpr",
    to: routes.gdpr.to
  }
];

const OurExpertiseText = styled.p`
  margin-top: 10px;
  color: ${props => props.theme.color.gray};
`;

const FooterTopSectionInner = (props: FooterTopSectionProps) => {
  const { t } = useTranslation("common");
  const officeOpeningHours: string[] = t(
    "contactInformation.officeOpeningHours",
    {
      returnObjects: true
    }
  );

  const change = {
    icon: <TiContacts />,
    text: "",
    component: (
      <a
        href="/documents/ALTRO_D3_Zmena správcovskej spoločnosti.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("header.topSection.changeOfServicingCompany")}
      </a>
    )
  };
  return (
    <div className={props.className}>
      <Container>
        <Grid container spacing={10}>
          <Grid item xs={12} md={4}>
            <HeaderUnderline>{t("footer.ourExpertise.title")}</HeaderUnderline>
            <SvgLogoDark />
            <OurExpertiseText>{t("footer.ourExpertise.text")}</OurExpertiseText>
          </Grid>
          <Grid item xs={12} md={4}>
            <HeaderUnderline>
              {t("footer.importantLinks.title")}
            </HeaderUnderline>
            <div className="links">
              {importantLinks.map(link => (
                <ImportantLink key={link.tk} text={t(link.tk)} to={link.to} />
              ))}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <HeaderUnderline>{t("footer.contacts.title")}</HeaderUnderline>
            <div className="contact">
              <div className="office">{t("footer.contacts.office")}</div>
              <a href={`tel:${t("contactInformation.phoneNumber")}`}>
                {t("contactInformation.phoneNumber")}
              </a>
              <p
                style={{
                  margin: "10px 0 5px"
                }}
              >
                Stránkové hodiny
              </p>
              {officeOpeningHours.map(hours => (
                <p key={hours}>{hours}</p>
              ))}
            </div>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={12}>
              <HeaderUnderline>ostatné LINKY</HeaderUnderline>
              <div className="additional">
                <TopHeaderItem {...change} />
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

export const FooterTopSection = styled(FooterTopSectionInner)`
  padding: 40px 0;
  background: ${props => props.theme.color.sectionBg};
  overflow-x: hidden;

  .office {
    color: ${props => props.theme.color.primary};
    font-weight: 500;
    margin-bottom: 5px;
  }

  ${TopHeaderItem} {
    color: ${props => props.theme.color.primary};
    & > .text {
      color: ${props => shade(0.5, props.theme.color.gray)} !important;
    }
  }

  .additional {
    margin-top: 10px;
  }
`;
