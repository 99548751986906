import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export interface FooterBottomSectionProps {
  className?: string;
}

const currentYear = new Date().getFullYear();

const FooterBottomSectionInner = (props: FooterBottomSectionProps) => {
  const { t } = useTranslation("common");
  return (
    <div className={props.className}>
      ALTRO {currentYear} {t("footer.allRightsReserved")}
    </div>
  );
};

export const FooterBottomSection = styled(FooterBottomSectionInner)`
  background: #00283c;

  color: ${props => props.theme.color.border};
  text-align: center;
  padding: 12px 0;
`;
