import React from "react";
import styled from "styled-components";
import { HeaderBottomSection } from "./components/HeaderBottomSection/HeaderBottomSection";
import { HeaderTopSection } from "./components/HeaderTopSection/HeaderTopSection";
import { Hidden } from "@material-ui/core";

interface HeaderProps {
  className?: string;
  dark?: boolean;
}

const HeaderInner: React.FC<HeaderProps> = (props: HeaderProps) => {
  return (
    <header className={props.className}>
      <Hidden smDown>
        <HeaderTopSection />
      </Hidden>
      <HeaderBottomSection darkHeader={props.dark} />
    </header>
  );
};

export const Header = styled(HeaderInner)<HeaderProps>``;
