export interface Route {
  translationKey: string;
  to: string;
  displayInMenu?: boolean;
}

export const routes: {
  [key: string]: Route;
} = {
  home: {
    translationKey: "routes.home",
    to: "/"
  },
  services: {
    translationKey: "routes.services",
    to: "/sluzby/"
  },
  documents: {
    translationKey: "routes.documents",
    to: "/tlaciva/"
  },
  contact: {
    translationKey: "routes.contact",
    to: "/kontakt/"
  },
  poschodoch: {
    translationKey: "routes.poschodoch",
    to: "/poschodoch/",
    displayInMenu: false
  },
  gdpr: {
    translationKey: "routes.gdpr",
    to: "/gdpr.pdf",
    displayInMenu: false
  }
};
