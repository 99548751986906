import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

export interface NavLinkProps {
  className?: string;
  to: string;
  label: string;
}

const NavLinkInner = (props: NavLinkProps) => {
  const { label, to } = props;
  return (
    <Link className={props.className} activeClassName="active" to={to}>
      <div className={"label-wrapper"}>{label}</div>
    </Link>
  );
};

export const NavLink = styled(NavLinkInner)`
  font-size: 2rem;
  font-weight: 700;
  height: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  transition: all 200ms ease-in-out;

  color: ${props => props.theme.color.primaryDark};
  margin: 0 20px;

  &:hover {
    color: ${props => props.theme.color.primary};
  }

  &.active {
    color: ${props => props.theme.color.primary};
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: -20%;
      width: 140%;
      height: 8px;
      background: ${props => props.theme.color.primary};
    }
  }
`;
