import React from "react";
import styled from "styled-components";
import { BiRightArrowAlt } from "react-icons/bi";
import GatsbyLink from "gatsby-link";
import {darken, shade} from "polished";

export interface ImportantLinkProps {
  className?: string;
  text: string;
  to: string;
}

const ImportantLinkInner = (props: ImportantLinkProps) => {
  const { text, to } = props;
  return (
    <GatsbyLink to={to} className={props.className}>
      <div className="arrow">
        <BiRightArrowAlt />
      </div>
      <div className="text">{text}</div>
    </GatsbyLink>
  );
};

export const ImportantLink = styled(ImportantLinkInner)`
  display: flex;
  
  :hover {
    .arrow {
       margin-right: 3px;
       margin-left: 7px;
    }
    .text {
      color: ${props => shade(0.5, props.theme.color.gray)}
    }
  }
  
  .arrow {
    display: flex;
    align-items: center;
    font-size: 35px;
    color: ${props => props.theme.color.primary};
    margin-right: 10px;
    transition: all 230ms ease-in-out;
  }

  .text {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.6rem;
    color: ${props => props.theme.color.gray};
    transition: all 230ms ease-in-out;
  }
`;
