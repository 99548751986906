import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import SvgLogoDark from "../../../../../../assets/LogoDark";
import { routes } from "../../../../../../lib/routes";
import SvgLogo from "../../../../../../assets/Logo";
import SvgLogoLight from "../../../../../../assets/LogoLight";

export interface LogoLinkProps {
  dark?: boolean;
}

const LogoLinkInner: React.FC<LogoLinkProps> = (props: LogoLinkProps) => {
  return (
    <Link to={routes.home.to} className={props.className}>
      {props.dark ? <SvgLogoLight /> : <SvgLogoDark />}
    </Link>
  );
};

export const LogoLink = styled(LogoLinkInner)<LogoLinkProps>`
  display: flex;
`;
