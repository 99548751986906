import React, { useEffect, useState } from "react";
import HamburgerMenu from "react-hamburger-menu";
import styled from "styled-components";
import { theme } from "../../../../../../lib/Theme";
import { AnimatePresence, motion } from "framer-motion";
import { routes } from "../../../../../../lib/routes";
import { LogoLink } from "./LogoLink";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";

export interface MobileMenuProps {
  iconColor?: string;
  className?: string;
}

const MobileMenuInner: React.FC<MobileMenuProps> = (props: MobileMenuProps) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const { t } = useTranslation("common");
  const { iconColor = theme.color.primary } = props;

  useEffect(() => {
    setTimeout(() => {
      if (mobileMenuIsOpen) {
        document.body.style.height = "100vh";
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.height = "";
        document.body.style.overflowY = "initial";
      }
    }, 230);
  }, [mobileMenuIsOpen]);

  const handleChangeMenuOpenedClicked = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };

  return (
    <div className={props.className}>
      <div
        onClick={handleChangeMenuOpenedClicked}
        className="mobile-menu__icon"
      >
        <HamburgerMenu
          isOpen={mobileMenuIsOpen}
          color={mobileMenuIsOpen ? theme.color.primary : iconColor}
          width={20}
          height={15}
          strokeWidth={2}
          animationDuration={0.23}
        />
      </div>
      <AnimatePresence>
        {mobileMenuIsOpen && (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            className={"mobile-menu__overlay"}
            transition={{
              stiffness: 50,
              duration: 0.23
            }}
          >
            <HomeLinkWrapper>
              <LogoLink />
            </HomeLinkWrapper>
            <MobileMenuItems>
              {Object.values(routes).map(
                route =>
                  route.displayInMenu !== false && (
                    <MobileMenuItemWrapper key={route.to}>
                      <MobileMenuItem activeClassName="active" to={route.to}>
                        {t(route.translationKey)}
                      </MobileMenuItem>
                    </MobileMenuItemWrapper>
                  )
              )}
              <MobileMenuItemWrapper>
                <MobileMenuItem
                  activeClassName="active"
                  to={routes.poschodoch.to}
                >
                  {t(routes.poschodoch.translationKey)}
                </MobileMenuItem>
              </MobileMenuItemWrapper>
            </MobileMenuItems>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const MobileMenuItems = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 2rem;
  width: 100%;
  flex: 1;
`;

const MobileMenuItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

const MobileMenuItem = styled(Link)`
  font-size: 2.8rem;
  text-decoration: none; /* no underline */
  text-transform: uppercase;
  color: ${props => props.theme.color.primaryDark};
  padding: 0 2.8rem;
  margin: 2rem 0;
  font-weight: 600;

  &.active {
    color: ${props => props.theme.color.primary};
  }
`;

const HomeLinkWrapper = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
  }
`;

const MobileMenuIcon = styled.div``;

export const MobileMenu = styled(MobileMenuInner)<MobileMenuProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
  .mobile-menu {
    &__icon {
      z-index: 10;
      padding: 20px;
    }
    &__overlay {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      background: white;
    }
  }
`;
