import React, { ReactNode } from "react";
import styled from "styled-components";
import { muiTheme } from "../../../../../../../../lib/Theme";

export interface TopHeaderItemProps {
  className?: string;
  icon: ReactNode;
  text?: string;
  component?: ReactNode;
}

const TopHeaderItemInner = (props: TopHeaderItemProps) => {
  const { icon, text, component } = props;
  return (
    <div className={props.className}>
      <div className="icon">{icon}</div>
      {component || <div className="text">{text}</div>}
    </div>
  );
};

export const TopHeaderItem = styled(TopHeaderItemInner)`
  display: flex;
  color: white;

  .icon {
    margin-right: ${muiTheme.spacing(1)}px;
    font-size: 22px;
    display: flex;
    align-items: center;
  }

  .text {
    display: flex;
    align-items: center;
  }
`;
