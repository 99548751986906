import { darken, lighten, rgba, shade } from "polished";
import { createMuiTheme } from "@material-ui/core";

const primaryColor = "#00A7E6";

export const theme = {
  name: "Default",
  color: {
    background: "#fff",
    primary: primaryColor,
    primaryDark: "#2A2925",
    gray: "rgba(0, 0, 0, 0.6)",
    primaryGradient: `linear-gradient(95.36deg, ${lighten(
      0.05,
      primaryColor
    )} 16.39%, ${shade(0.18, darken(0, primaryColor))} 130.71%)`,
    sectionBg: "#F6F6F6",
    border: "#D4D2D3"
  },
  borderRadius: "16px",
  spacing: {
    sectionMarginTop: "65px"
  }
};

export const muiTheme = createMuiTheme();
