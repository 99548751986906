import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../Theme/components/GlobalStyle";
import { theme } from "../../../lib/Theme";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import ResetStyle from "../Theme/components/Reset";

const StyledMain = styled.main`
  max-width: 100vw;
  overflow: hidden;
`;

const Layout = ({ children, noHeader }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          {!noHeader && <Header />}
          <StyledMain>{children}</StyledMain>
          <Footer />
        </ThemeProvider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
