import React, { ReactNode } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Heading } from "../Heading/Heading";

export interface HeaderUnderlineProps {
  children?: ReactNode;
  className?: string;
  align?: "center" | "flex-start";
}

const HeaderUnderlineInner = (props: HeaderUnderlineProps) => {
  const { children } = props;
  return (
    <div className={props.className}>
      <Heading>{children}</Heading>
      <div className="underline" />
    </div>
  );
};

export const HeaderUnderline = styled(HeaderUnderlineInner)`
  display: flex;
  flex-flow: column;
  align-items: ${props => props.align || "flex-start"};
  color: ${props => props.theme.color.primaryDark};
  font-weight: 500;
  margin-bottom: 30px;

  ${Heading} {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: ${props =>
      props.align === "flex-start" || props.align == null ? "left" : "center"};
  }

  .underline {
    width: 10rem;
    height: 8px;
    background: ${props => props.theme.color.primary};
  }
`;
