import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
   html {
      font-size: 62.5%;
      @media screen and (max-width: 600px) {
        font-size: 58%;
      }
  }

  body {
    font-size: 1.6rem;
    font-family: 'Open Sans', sans-serif;
    color: ${props => props.theme.color.primaryDark};
  }
  
  * {
    margin: 0;
  }

  p {
    color: ${props => props.theme.color.gray};
  }
  
  a {
    text-decoration: none;
    color: ${props => props.theme.color.primaryDark};
  }
  
  h2 {
    font-weight: 600;
    margin-bottom: 20px;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  /*  Used for hiding form labels, to achieve 100% accessibility */
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;
